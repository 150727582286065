import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

import Markup from '../../Elements/Markup';
import { ContactFormType } from '../../../types';

const HubspotContactForm = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [fullname, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');

  const submitForm = (e, portalID, formID) => {
    if (e) e.preventDefault();

    const isBrowser = typeof window !== 'undefined';
    const hutk = isBrowser ? Cookies.get('hubspotutk') : null;
    const pageUri = isBrowser ? window.location.href : null;
    const pageName = isBrowser ? document.title : null;
    const hubspotUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalID}/${formID}`;

    setLoading(true);

    const body = {
      submittedAt: Date.now(),
      fields: [{
          name: 'email',
          value: email,
        }, {
          name: 'full_name',
          value: fullname,
        }, {
          name: 'company',
          value: company,
        }, {
          name: 'message',
          value: message,
        }
      ],
      context: {
        hutk,
        pageUri,
        pageName,
      },
    };

    axios({
        url: hubspotUrl,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
        },
        data: JSON.stringify(body)
      })
      .then(() => {
        setSuccess(true);
        setError(false);
        setLoading(false);
        setEmail('');
        setFullName('');
        setCompany('');
        setMessage('');
      })
      .catch(err => {
        console.error(err);
        setSuccess(false);
        setError(err);
        setLoading(false);
      });
  };

  const renderSuccessMessage = () => (
    data?.successMessage?.processed
      ? <Markup className="success-message" content={data.successMessage.processed} />
      : <p>Success!</p>
  );

  const renderErrorMessage = () => (
    data?.errorMessage?.processed
      ? <Markup className="error-message" content={data.errorMessage.processed} />
      : <p>Error!</p>
  );

  const renderHubspotForm = () => {
    return (
      <form
        className="form-contact"
        data-form-id={data.formID}
        data-portal-id={data.portalID}
        disabled={loading}
        onSubmit={(e) => submitForm(e, data.portalID, data.formID)}>
        { success
          ? renderSuccessMessage()
          : (
            <>
              <div className="form-group">
                <div className="input-wrapper">
                  <input
                    type="text"
                    name="fullname"
                    placeholder=""
                    value={fullname}
                    onChange={e => setFullName(e.target.value)}
                    required
                  />
                  <span>Your full name*</span>
                </div>
                <div className="input-wrapper">
                  <input
                    type="email"
                    name="email"
                    placeholder=""
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                  />
                  <span>Your email*</span>
                </div>
                <div className="input-wrapper">
                  <input
                    type="text"
                    name="company"
                    placeholder=""
                    value={company}
                    onChange={e => setCompany(e.target.value)}
                    required
                  />
                  <span>Your organization*</span>
                </div>
                <div className="input-wrapper">
                  <textarea
                    type="text"
                    name="message"
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    placeholder=""
                  />
                  <span>Tell us about your project</span>
                </div>
              </div>
              {error && renderErrorMessage()}
              <button className="btn-solid btn-normal" type="submit" value="submit" name="submit">Submit</button>
            </>
          )
        }
      </form>
    );
  };

  return (
    <div className="contact-wrapper">
      {/* {data?.heading?.processed ? <Markup className="msj" content={data?.heading?.processed} /> : null} */}
      {renderHubspotForm()}
    </div>
  );
};

HubspotContactForm.propTypes = { data: ContactFormType };

export default HubspotContactForm;
