import React from 'react';
import { FiPhone } from 'react-icons/fi';
import { FiMail } from 'react-icons/fi';
import { FiMapPin } from 'react-icons/fi';
import CompanySocialIcons from '../../Social/CompanySocialIcons';
import { ContactChannelsType } from '../../../types';

const ContactChannels = ({ data }) => (
  <>
  
    {data.phoneNumbers?.length ?
      (
        <div className="contact-item tel">
          {data.phoneNumbers.map(phoneNumber => (
            <div className=" tel-inner" key={phoneNumber}>
              <h3>
                <a href={`tel:${phoneNumber}`}>
                  <div className="icon"><FiPhone /></div>
                  {phoneNumber}
                </a>
              </h3>
            </div>
          ))}
        </div>
      ) : null}

    {data.emails?.length ?
      (
        <div className="contact-item email">
          {data.emails.map(email => (
            <div className="email-inner" key={email}>
              <h3>
                <a href={`mailto:${email}`}>
                  <div className="icon">
                    <FiMail />
                  </div>
                  {email}
                </a>
              </h3>
            </div>
          ))}
        </div>
      ) : null}

    {data.addresses?.length ?
      (
        <div className="contact-item location">
          {data.addresses.map(address => (
            <div className="location-inner" key={address.title}>
              <h3>
                <a href={address.uri} target="_blank" rel="noreferrer">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  {address.title}
                </a>
              </h3>
            </div>
          ))}
        </div>
      ) : null}

    <CompanySocialIcons />
  </>
);

ContactChannels.propTypes = { data: ContactChannelsType };

export default ContactChannels;
